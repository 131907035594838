import React, {useEffect, useState} from "react";
import {Redirect, Route, useLocation} from "react-router-dom";
import {AuthProvider} from "./context/AuthContext";
import {Layout} from './components/Layout';
import Home from './pages/Home/Home';
import ReplayIntegracaoTotal from './components/Integracao/ReplayIntegracaoTotal';
import ReplayReservaTotal from './components/Integracao/ReplayReservaTotal';
import {ReplayIntegracaoUpSellingTotal} from './components/IntegracaoUpSelling/ReplayIntegracaoUpSellingTotal';
import {ReplayIntegracaoParcial} from './components/Integracao/integracaoParcial/ReplayIntegracaoParcial';
import {ReplayIntegracaoUpSellingParcial} from './components/IntegracaoUpSelling/ReplayIntegracaoUpSellingParcial';
import {ReplayIntegracaoRegularParcial} from './components/Integracao/ReplayIntegracaoRegularParcial';
import {ReplayIntegracaoReservaParcial} from './components/Integracao/ReplayIntegracaoReservaParcial';
import BlockedResources from "./pages/BlockedResources/BlockedResources";
import BlockedResourcesNovo from "./pages/BlockedResources/Novo/Novo";
import updateSiteTheme from "./utils/updateSiteTheme";
import * as _blackListService from "./service/blacklist-service";
import ManutencaoFinanceiraFrank from "./pages/ManutencaoFinanceiraFrank/ManutencaoFinanceiraFrank";
import ManutencaoFinanceira from "./pages/ManutencaoFinanceira/ManutencaoFinanceira";
import Matricula from "./pages/Matricula/Matricula";
import Responsaveis from "./pages/Responsaveis/Responsaveis";
import Financeiro from "./pages/Financeiro/Financeiro";
import TrocaDeTurma from "./pages/TrocaDeTurma/TrocaDeTurma";
import TrocaDeCurso from "./pages/TrocaCurso/TrocaCurso";
import Bolsas from "./pages/Bolsas/Bolsas";
import Cancelamento from "./pages/Cancelamento/Cancelamento";
import TrocaResponsavelFinanceiro from "./pages/TrocaResponsavelFinanceiro/TrocaResponsavelFinanceiro";
import TrocaResponsavelPedagogico from "./pages/TrocaResponsavelPedagogico/TrocaResponsavelPedagogico";
import TrocaMetodoPagamento from "./pages/TrocaMetodoPagamento/TrocaMetodoPagamento";
import AtualizarContratoMovimentacoes from "./pages/AtualizarContratoMovimentacoes/AtualizarContratoPage";
import AtualizarContratoIntegracoes from "./pages/AtualizarContratoIntegracoes/AtualizarContratoPage";
import ReconhecimentoFacial from "./pages/ReconhecimentoFacial/ReconhecimentoFacial";
import Colaboradores from "./pages/Colaboradores/Colaboradores";
import Publicidade from "./pages/Publicidade/Publicidade";
import PlanoDePagamento from "./pages/PlanoDePagamento/PlanoDePagamento";
import CarteirnhaEscolar from "./pages/CarteirinhaEscolar/CarteirinhaEscolar";
import {SearchDialog} from "./components/SearchDialog/search-dialog";
import {useDialog} from "./hooks/use-dialog";
import ShortCuts from "./components/ShortCuts/ShortCuts";
import PermissaoHorario from "./pages/PermissaoHorario/PermissaoHorario";
import DeclaracaoDeBolsa from "./pages/DeclaracaoBolsa/DeclaracaoDeBolsa";
import DeclaracaoDeRacaCor from "./pages/DeclaracaoRacaCor/DeclaracaoDeRacaCor";
import {useCan} from "./hooks/use-can";
import nprogress from "nprogress";
import {useRecoilValue} from "recoil";
import ReplayParcialMaterial from './pages/ReplayParcialMaterial/ReplayParcialMaterial';
import {selectedUnidadeState} from "./recoil/atoms/selectedUnidadeState";
import {useAnalytics} from "./hooks/use-analytics";
import {gtmConfig} from "./config";

import * as Yup from "yup";
import {pt} from "yup-locale-pt";
nprogress.configure({ showSpinner: false });

Yup.setLocale(pt);

export const AppContext = React.createContext({
	BlockedResources: null,
});

function App() {
	const dialog = useDialog();
	const {userCan} = useCan();
	let location = useLocation();
	const selectedUnidade = useRecoilValue(selectedUnidadeState);
	useAnalytics(gtmConfig);

	useEffect(() => {
		nprogress.start();
		nprogress.done();
	}, [location.pathname]);

	React.useEffect(() => {
		updateSiteTheme();
	}, []);

	const [blockedResources, setBlockedResources] = useState(null);

	React.useEffect(() => {
		if (blockedResources === null && window.apolloClient) {
			const fetchBlockedResources = async () => {
				_blackListService.GetList(`${process.env.REACT_APP_CLIENT_ID}`).then(data => {
					setBlockedResources(data?.value || []);
				}).catch(error => console.log(error));
			}

			fetchBlockedResources();
		}
	}, [blockedResources, setBlockedResources, window.apolloClient]);

	return (
		<AuthProvider>
			<AppContext.Provider value={{blockedResources, setBlockedResources}}>
				<Layout>
					<Route exact path='/' render={() => <Redirect to="/home"/>}/>
					<Route exact path='/home' component={Home}/>
					<Route exact path='/matricula/detalhes' component={Matricula}/>
					<Route exact path='/matricula/responsaveis' component={Responsaveis}/>
					<Route exact path='/matricula/financeiro' component={Financeiro}/>
					<Route exact path='/movimentacoes/troca-de-turma' component={TrocaDeTurma}/>
					<Route exact path='/movimentacoes/troca-de-curso' component={TrocaDeCurso}/>
					<Route exact path='/movimentacoes/responsavel-financeiro' component={TrocaResponsavelFinanceiro}/>
					<Route exact path='/movimentacoes/plano-de-pagamento' component={PlanoDePagamento}/>
					<Route exact path='/movimentacoes/cancelamento' component={Cancelamento}/>
					<Route exact path='/movimentacoes/bolsas' component={Bolsas}/>
					<Route exact path='/movimentacoes/troca-metodo-pagamento' component={TrocaMetodoPagamento}/>
					<Route exact path='/movimentacoes/troca-responsavel-pedagogico' component={TrocaResponsavelPedagogico}/>
					<Route exact path='/movimentacoes/atualizacao-de-contrato' component={AtualizarContratoMovimentacoes}/>
					{userCan("integração.replayTotal") && (
						<Route exact path='/integracao/replay-total' component={ReplayIntegracaoTotal}/>
					)}
					{userCan("integração.replayTotalUpSelling") && (
						<Route exact path='/integracao/replay-total-upselling' component={ReplayIntegracaoUpSellingTotal}/>
					)}
					{userCan("integração.contrato") && (
						<Route exact path='/integracao/contrato' component={AtualizarContratoIntegracoes}/>
					)}
					{userCan("integração.replayParcial") && (
						<Route exact path='/integracao/replay-parcial' component={ReplayIntegracaoParcial}/>
					)}
					{userCan("integração.replayParcialUpSelling") && (
						<Route exact path='/integracao/replay-parcial-upselling' component={ReplayIntegracaoUpSellingParcial}/>
					)}
					{userCan("integração.replayParcialRegular") && (
						<>
							<Route exact path='/integracao/replay-parcial-regular' component={ReplayIntegracaoRegularParcial}/>
						</>
					)}
					{userCan("integração.replayParcialReserva") && (
						<>
							<Route exact path='/integracao/replay-parcial-reserva' component={ReplayIntegracaoReservaParcial}/>
						</>
					)}
					{userCan("integração.replayTotalReserva") && (
						<Route exact path='/integracao/replay-total-reserva' component={ReplayReservaTotal}/>
					)}
					{userCan("integração.replayParcialMaterial") && (
						<Route exact path='/integracao/replay-parcial-material' component={ReplayParcialMaterial}/>
					)}
					{userCan("integração.bloqueioDeRecursos") && (
						<>
							<Route exact path='/integracao/bloqueio-de-recursos' component={BlockedResources}/>
							<Route exact path='/integracao/bloqueio-de-recursos/novo' component={BlockedResourcesNovo}/>
						</>
					)}
					{userCan("integração.manutencaoFinanceira") && (
						<>
							<Route exact path='/integracao/manutencao-financeira' component={ManutencaoFinanceiraFrank}/>
							<Route exact path='/movimentacoes/manutencao-financeira' component={ManutencaoFinanceira}/>
						</>
					)}
					{/*<Route exact path='/conciliacao' component={PainelConciliacaoMatricula} />*/}
					<Route exact path='/declaracoes/bolsa' component={DeclaracaoDeBolsa}/>
					<Route exact path='/declaracoes/raca-cor' component={DeclaracaoDeRacaCor}/>
					{selectedUnidade?.possuiCarteirinha && 
						<Route exact path='/declaracoes/carteirinha-escolar' component={CarteirnhaEscolar} />
					}

					{selectedUnidade?.possuiFacial && (
						<>
							<Route exact path='/reconhecimento-facial/alunos' component={ReconhecimentoFacial}/>
							<Route exact path='/reconhecimento-facial/responsaveis' component={ReconhecimentoFacial}/>
							<Route exact path='/reconhecimento-facial/colaboradores' component={Colaboradores}/>
                            <Route exact path='/reconhecimento-facial/publicidade' component={Publicidade}/>
							<Route exact path='/reconhecimento-facial/permissao-horarios' component={PermissaoHorario}/>
						</>
					)}
				</Layout>

				<ShortCuts
					commands={[
						{
							handler: e => e.ctrlKey && e.key === '/',
							onMatch: dialog.handleOpen
						}
					]}
				/>
				<SearchDialog
					onClose={dialog.handleClose}
					open={dialog.open}
				/>
			</AppContext.Provider>
		</AuthProvider>
	);
}

export default App;

